.first-section {
    margin-top: 20px;
  }
  .web-img {
    width: 100%;
    /* height: 600px; */
  }
  .second-section {
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 50px;
    gap: 30px;
  }
  .bread-crumbs {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 18px;
    color: #363636;
    text-align: left;
  }
  .brd {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 18px;
    color: #FF9515;
  }
  .heading-web {
    text-align: left;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 40px;
    color: #363636;
  }
  .card-in {
    width: 350px;
    height: 250px;
    border: 1px solid #FF9515;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    padding: 20px;
    box-sizing: border-box;
  }
  .flex-card {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: nowrap;
  }
  .ourservice{
    cursor: pointer;
  }
  .card-content-1 {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    flex-grow: 1;
    gap: 15px;
    margin-top: 30px
  }
  .card-title-1 {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 18px;
    color: #363636;
    text-align: left;
    margin: 0;
  }
  .card-sub-content {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    color: #363636;
    text-align: left;
    margin: 0;
    overflow-y: hidden;
  }
  .list-items {
    font-size: 15px;
    font-weight: 400;
    font-family: 'Poppins';
    margin-bottom: 20px; 
    word-wrap: break-word;
    text-align: left;

}
.li-div{
    padding-right: 150px;
    margin-bottom: 20px;
}
ul {
    list-style: none;
    padding-left: 0;
}

ul li {
    position: relative;
    padding-left: 1.5em;
    margin-bottom: 1em;
}

ul li:before {
    content: '•';
    position: absolute;
    left: 0;
    top: 0;
}
.bold-para{
    font-family: 'Poppins';
    font-weight: 700;
  }
  .li-div {
    padding: 20px;
  }
  
  
  .inner-ul {
    list-style-type: disc;
    margin-left: 20%;
    margin-top: 2%;
  }
  
  .inner-ul .list {
    margin-bottom: 5px;
  }
  @media (max-width: 1024px) {
    .card-in{
     height: 320px;
    }
    .inner-ul {
        list-style-type: disc;
        margin-left: 15%;
        margin-top: 30px;
      }
      
   }
  
 
  @media (max-width: 768px) {
   
    .bread-crumbs {
      font-size: 16px;
    }
    .inner-ul {
        list-style-type: disc;
        margin-left: 10%;
        margin-top: 30px;
      }
      .li-div {
        padding: 0px;
      }
     
    .list-items {
    
        font-size: 14px;
        font-weight: 400;
    }
    .bold-para{
        font-size: 14px;
        font-weight: 700;
      }
    .brd {
      font-size: 16px;
    }
    .heading-web {
      font-size: 30px;
    }
    .flex-card {
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .card-in {
      width: 48%; 
      max-width: 100%;
      height: 230px;
    }
    .card-title-1 {
      font-size: 16px;
    }
    .card-sub-content {
      font-size: 12px;
    }
    .first-section{
        margin-top: 0px;
    }
    .second-section{
        margin-top: 0px;
        gap:25px
    }
  }
  @media(max-width:600px){
    .web-img {
      display: none;
    }
    .flex-card{
      justify-content: center;
    }
   }
  @media (max-width: 480px) {
    .bread-crumbs {
      font-size: 13px;
    }
    .brd {
      font-size: 13px;
    }
    .list-items {
       /* text-align: justify; */
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 30px;
        
    }
    .bold-para{
        font-size: 13px;
        font-weight: 700;
      }
    .inner-ul {
        list-style-type: disc;
        margin-left: 5%;
        margin-top: 30px;
      }
    .li-div{
        padding-right: 0px;
    }
    .heading-web {
      font-size: 22px;
    }
    .flex-card {
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }
    .first-section{
        margin-top: 0px;
    }
    .second-section{
        margin-top: 0px;
        gap: 10px;
    }
    .card-in {
      width: 100%; 
      padding: 15px;
      height: auto;
    }
    .card-title-1 {
      font-size: 14px;
    }
    .card-sub-content {
      font-size: 12px;
    }
    .card-content-1{
        margin-top: 10px;
    }
    .li-div{
        margin-top: 15px;
        padding: 0px;
    }
  }
 
  