.image-styles{
    max-width: 250px;
    width: 100%;
}
@media(max-width: 900px){
    .image-styles{
        max-width: 200px;
        width: 100%;
    } 
}
@media(max-width: 768px){
    .image-styles{
        max-width: 150px;
        width: 100%;
    } 
}
@media(max-width: 480px){
    .image-styles{
        max-width: 100px;
        width: 100%;
    } 
}