
.main-footer{
    background: #363636;
    width: 100vw;
    padding: 70px;
    box-sizing: border-box;
}

.copy-right{
    border-top: 1px solid white;
   
}
.copyright-text{
    /* margin-top: 10px; */
    padding: 30px 60px 10px;
    box-sizing: border-box;
    text-align: center;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;
    color: white;
}
.footer-section{
    display: flex;
    justify-content: space-between;
}
.follow{
    margin-top: 20%;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px;
    color: white;
    text-align: left;
}
.right-footer{
    display: flex;
    /* margin-top: 1%; */
    gap: 160px;
    justify-content: space-around;
    padding-right: 30px;
}
.quick{
    font-family:'Montserrat' ;
    font-size: 16px;
    font-weight: 700;
    color: #FF9515;
    text-align: left;
}
.first-sec{
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.first-sec-sub{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.links{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    color: white;
}
@media (max-width: 768px) {
    .footer-section{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .follow, .quick, .links, .copyright-text {
        font-size: 14px;
      }
      .follow{
        margin-top: 10%;
      }
      .right-footer {
       justify-content: flex-start;
       margin-top: 4%;
      }
      .image{
        width: 30px;
        height: 30px;
      }
      .main-footer{
        padding: 50px;
      }
  }
 @media (max-width:600px) {
    .right-footer {
        flex-direction: column;
        gap: 40px; 
        align-items: flex-start; 
        margin-top: 6%;
      }
      .follow, .quick, .links, .copyright-text {
        font-size: 12px;
      }
 } 