.main-div {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 20px;
  gap: 20px;
}

.flex-display-1 {
  padding: 40px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 15px;
}

.image-container {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex: 1;
}

.about-1 {
  margin-top: 20px;
  text-align: left;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 20px;
  color: #ff9515;
}

.service-para {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 40px;
  color: #363636;
  text-align: left;
  line-height: 1.2;
  overflow-y: hidden;
}

.sub-div {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.career-2 {
  font-family: "Poppins";
  font-weight: 400;
  color: #363636;
  font-size: 16px;
  overflow-y: hidden;
  text-align: justify;
}

.why {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 48px;
  color: #ff9515;
  text-align: left;
}
.secnd-sec {
  padding: 0px 55px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.flex-card-in {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: nowrap;
  margin-bottom: 10%;
}
.about-card {
  width: calc(50% - 15px);
  height: 210px;
}
.about-content-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  margin-top: 4%;
}
.about-card-title {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 18px;
  color: #363636;
  text-align: left;
  margin: 0;
}
.about-content-1 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #363636;
  text-align: left;
  margin: 0;
  overflow-y: hidden;
}
.third-sec {
  background: white;
  display: flex;
  justify-content: space-around;
}
.mission {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.our {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 48px;
  color: #363636;
  text-align: left;
}
.mission-1 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 46px;
  color: #ff9515;
  text-align: left;
}

.third-sec {
  background-color: white;
  color: #fff;
}

.content-wrapper {
  padding: 150px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.mission {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.mission-1 {
  margin: 0;
  font-family: "Poppins";
  font-size: 48px;
  color: #ff9515;
  font-weight: 700;
}

.description {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  max-width: 60%;
  text-align: justify;
}
.fourth-sec {
  padding: 40px;
  margin-bottom: 5%;
}
.div-desc {
  margin-top: 2%;
}
.image-div {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 40px;
  flex-wrap: nowrap;
}
.image-sec {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
  height: fit-content;
}
.text-person {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 16px;
  color: #ff9515;
}
.text-person-1 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #363636;
}
.inner-image-sec{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.image-person{
  height: 100%;
}

@media (max-width: 1200px) {
  .career-2 {
    font-size: 15px;
  }
  .why {
    font-size: 38px;
  }
}

@media (max-width: 992px) {
  .image-container {
    display: none;
  }
  .career-2 {
    font-size: 14px;
  }
  .about-card {
    height: auto;
    width: 100%;
  }
  .why {
    font-size: 35px;
  }
  .about-card-title {
    font-size: 17px;
  }
  .about-content-card {
    padding: 20px;
  }

  .our {
    font-size: 38px;
  }
  .mission-1 {
    font-size: 38px;
  }
  .description {
    font-size: 14px;
  }
  .text-person {
    font-size: 15px;
  }
  .text-person-1 {
    font-size: 13px;
  }
  .inner-image-sec{
    gap: 0px;
  }
}

@media (max-width: 768px) {
  .image-div {
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
  }
  .service-para {
    font-size: 35px;
  }
  .flex-display-1 {
    padding: 0px 40px;
  }
  .secnd-sec {
    padding: 0px 55px;
  }
  .main-div {
    flex-direction: column;
  }
  .career-2 {
    font-size: 13px;
  }
  .about-card-title {
    font-size: 16px;
  }
  .about-card {
    width: 70%;
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
  }
  .flex-card-in {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .why {
    font-size: 32px;
  }
  .about-card-title {
    font-size: 16px;
  }
  .about-content-1 {
    font-size: 13px;
  }
  .our {
    font-size: 34px;
  }
  .mission-1 {
    font-size: 34px;
  }
  .description {
    font-size: 13px;
    max-width: 100%;
  }
  .content-wrapper {
    padding: 40px 40px;
    align-items: flex-start;
    gap: 20px;
  }
  .text-person {
    font-size: 14px;
  }
  .text-person-1 {
    font-size: 12px;
  }
  .inner-image-sec{
    gap: 0px;
  }
  .image-person{
    height: 300px;
  }
  .fourth-sec {
    padding: 0px;
  }
}

@media (max-width: 480px) {
  .flex-display-1 {
    padding: 20px;
  }
  .service-para {
    font-size: 25px;
  }
  .career-2 {
    font-size: 13px;
    text-align: justify;
  }
  .about-1 {
    margin-top: 0px;
  }
  .sub-div {
    gap: 20px;
  }
  .flex-card-in {
    display: flex;
    flex-direction: column;
  }
  .why {
    font-size: 28px;
  }
  .about-card {
    width: 100%;
  }
  .secnd-sec {
    padding: 0px 40px;
  }
  .about-card-title {
    font-size: 15px;
  }
  .about-content-card {
    gap: 10px;
  }
  .about-content-1 {
    font-size: 12px;
  }
  .our {
    font-size: 28px;
  }
  .mission-1 {
    font-size: 28px;
  }
  .description {
    font-size: 12px;
    max-width: 100%;
  }
  .content-wrapper {
    padding: 30px 40px;
    align-items: flex-start;
    gap: 20px;
  }
  .text-person {
    font-size: 13px;
  }
  .text-person-1 {
    font-size: 12px;
  }
  .inner-image-sec{
    gap: 0px;
  }
  .image-person{
    height: 250px;
  }
  .fourth-sec {
    padding: 15px;
  }
}
