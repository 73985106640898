.Contactformtext {
    text-align: left;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
  }
  
  .flex-display-11 {
    padding: 40px ;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    gap: 15px;
  }
  
  .button-text1 {
    color: white;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 18px;
  }
  .form-container{
    margin-top: 6%;
  }
  .last-sec {
    margin-top: 16%;
    display: flex;
    justify-content: flex-start;
  }
  
  .main-div-contact {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 20px 0px 0px 20px;
    gap: 20px;
  }
  
  .contact-section {
    color: #363636;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 20px;
    text-align: left;
  }
  
  .contact-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .contact-info-1 {
    display: flex;
    gap: 10px;
  }
  
  .contact-div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .content-style {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
  }
  .map-section{
    background: white;
    padding: 60px 80px ;
  }

  
  @media (max-width: 960px) {
    .main-div-contact {
      flex-direction: column;
    }
  
    .flex-display-11 {
      padding: 20px;
    }
  
    .form-container {
      margin-top: 6%;
    }
  
    .last-sec {
      margin-top: 5%;
    }
  }

  @media (max-width: 768px) {
    .Contactformtext {
        font-size: 15px;
      }
      .content-style {
        font-size: 15px;
      }
      .form-container {
        margin-top: 5%;
      }
      .button-text1 {
        font-size: 16px;
    }
    .contact-section {
        font-size: 18px;
    }
    .form-container {
        margin-top: 6%;
      }
      .map-section{
        padding: 40px 60px;
      }
  }

  @media (max-width: 480px) {
    .Contactformtext {
        font-size: 13px;
      }
      .button-text1 {
        font-size: 14px;
    }
    .content-style {
        font-size: 14px;
      }
      .contact-section {
        font-size: 16px;
    }
    .flex-display-11 {
        padding: 20px;
    }
    .map-section{
        padding: 20px 40px;
      }
  }
  