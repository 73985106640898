
* {
  margin: 0;
  padding: 0;
}


.image-container1 {
  display: block;
}

.content-container1 {
  text-align: left;
}

.flex-display {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}


.service {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 25px;
  color: #FF9515;
  margin-bottom: 0;
}

.service-para {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 40px;
  color: #363636;
  text-align: left;
  line-height: 1.2;
  overflow-y: hidden;
}

.card-container1 {
  display: flex;
  justify-content: space-between;
}
.card-container{
  margin-top: 50px;
}

.inline {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 0px 0px;
  gap: 30px;
}

.placeholder-card {
  visibility: hidden;
}

.card-style {
  height: 350px;
  width: 100%;
  max-width: 350px;
  border: 1px solid #363636;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

/* .card-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
} */

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-grow: 1;
  justify-content: center;
  text-align: center;

}


.card-img{
  width: "100%";
  max-width: "70px"; 
  height: "auto";
  display: "block";
  margin: "0 auto";
}

.card-title {
  color: #FF9515;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 14px;
  /* height: 45px; */
  display: flex;
  justify-content: center;
  text-align: center;
}

.card-subcontent {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  color: #363636;
  height: 80px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-1 {
  width: 100%;
  height: 40px;
  max-width: 150px;
  padding: 0px;
}
.button-text{
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 13px;
}
/* Media Queries */
@media (max-width: 1024px) {
 .image-container1{
  width: 100%;
 }
  .button-1 {
    max-width: 130px;
  }
  .button-text {
    font-size: 12px;
  }
  .card-subcontent {
    height: 120px;
  }
  .card-container{
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .placeholder-card {
    display: none;
  }
  .inline {
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
  }
  .card-container{
    margin-top: 20px;
  }
  .image-container1 {
    display: none;
  }
  .card-title {
    height: auto;
  }
  .card-subcontent {
    height: auto;
  }
  .button-1 {
    max-width: 120px;
    height: 35px;
    padding: 0px;
  }

  /* .button-1 {
    width: 100%;
    max-width: 120px;
    padding: 0px;
    height: 35px;
  } */
  /* .button-text {
    font-size: 13px;
    padding: 0px;
  } */
  /* .button-text{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 13px;
  } */
  .card-style {
    width: 250px;
    height: 280px;
  }
  .card-img{
    width: 100%;
    max-width: 60px;
    align-items: center;
    justify-content: center;
  }
  .card-title {
    font-size: 14px;
  }

  .card-subcontent {
    font-size: 10px;
  }

  .flex-display {
    padding: 10px;
    gap: 5px;
  }

  .service {
    font-size: 15px;
  }

  .service-para {
    font-size: 35px;
    /* line-height: 30px; */
  }

  /* .inline {
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
  } */
}

@media (max-width: 480px) {
  .card-container{
    margin-top: 10px;
  }
  .image-container1 {
    display: none;
  }
  .button-1 {
    width: 100%;
    max-width: 100px;
    height: 35px;
  }
  .button-text{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
  }
.card-style{
  width: 100%;
  height: auto;
}

 
  .card-content{
    gap:10px
  }

  .card-title {
    font-size: 14px;
    height: auto;
  }

  .card-subcontent {
    font-size: 10px;
  }

  .flex-display {
    padding: 5px;
    gap: 3px;
  }

  .service {
    font-size: 12px;
  }

  .service-para {
    font-size: 25px;
  }

  .inline {
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
  }
}
