* {
  margin: 0;
  padding: 0;
}
.main {
  background: white;
  overflow-x: hidden;
  justify-content: space-between;
}
.main-div-1{
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding:0px 20px;
  gap: 20px;
}

.raigad-head{
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 38px;
  color: #FF9515;
  text-align: left;
  text-transform: uppercase;
}
.flex-display-1-1 {
  margin-top: 10%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 15px;
}
.button-consult{
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 16px;
}
.career-2-3 {
  font-family: "Poppins";
  font-weight: 400;
  color: #363636;
  font-size: 16px;
  overflow-y: hidden;
  text-align: justify;
}

@media (max-width: 1200px) {
  .career-2-3 {
    font-size: 15px;
  }
  
}

@media (max-width: 992px) {
  .raigad-head{
    font-size: 35px;
  }
    .career-2-3 {
      font-size: 14px;
    }
}
@media (max-width: 768px) {
  .raigad-head{
    font-size: 30px;
  }
  .career-2-3 {
    font-size: 13px;
  }
  .flex-display-1-1{
    margin-top: 0px;
    padding: 30px;
  }
}
@media (max-width: 480px) {
  .raigad-head{
    font-size: 25px;
  }
  .career-2-3 {
    font-size: 12px;
    text-align: left;
  }
}