.testimonial-main {
  background: white;
  padding: 70px 150px;
  margin-bottom: 5%;
}
.testimonial-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.inner-test {
  display: flex;
  flex-direction: column;
  gap: 70px;
}
.image-test {
  display: flex;
  gap: 10px;
}
.testimonial-content {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 40px;
}
.test-content {
  color: black;
  text-align: left;
  font-size: 16px;
  font-family: "Poppins";
  margin-bottom: 0;
}
.content-main {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: flex-start;
}
.person-name {
  text-align: left;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 18px;
  color: black;
}
.position {
  text-align: left;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #ff9515;
}
.image-test {
  width: 100%;
  max-width: 200px;
}
.arrow {
  justify-content: center;
  display: flex;
  gap: 30px;
}
.arrow-img {
  cursor: pointer;
}
.testimonial-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.testimonial-slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.testimonial-slide {
  min-width: 100%;
}

@media (max-width: 1200px) {
  .image-test {
    width: 100%;
    max-width: 110px;
  }
}
@media (max-width: 992px) {
  .testimonial-main {
    padding: 70px 70px;
  }
  .test-content {
    font-size: 15px;
  }
}
@media (max-width: 768px) {
 
  .testimonial-content {
    align-items: flex-start;
    flex-direction: column; 
    gap: 20px;
  }
  .test-content {
    font-size: 13px;
    text-align: justify;
  }
}
@media (max-width: 480px) {
  .testimonial-main {
    padding: 40px;
  }
  .test-content {
    font-size: 12px;
  }
  .person-name {
    font-size: 14px;
  }
}
