.cv-style {
  padding: 50px 0px;
  background: #e3e3e3;
}
.send-cv {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #ff9515;
}
.send-style {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-left: 20%;
  padding-right: 20%;
}
.send-cv-content {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 500;
  text-align: left;
}
.content-cv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
}
.experience {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 700;
}
.experience-sub {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
}
.content-new {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.button-text12 {
  color: white;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
}
@media (max-width: 768px) {
  .button-text12 {
    font-size: 15px;
  }
  .send-cv-content {
    font-size: 18px;
  }
  .send-cv {
    font-size: 16px;
  }
  .send-style {
   padding-left: 15%;
   padding-right: 15%;
   gap: 30px;
  }
  .experience {
    font-size: 14px;
  }
  .experience-sub {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .button-text12 {
    font-size: 13px;
  }
  .send-cv-content {
    font-size: 16px;
  }
  .send-cv {
    font-size: 14px;
  }
  .send-style {
   padding-left: 10%;
   padding-right: 10%;
   gap: 20px;
  }
  .experience {
    font-size: 12px;
  }
  .experience-sub {
    font-size: 12px;
  }
}
