.main-career {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 20px;
}
.backgrnd-img {
  background-image: url("../../utils/Images/Image BG.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}
.career {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  color: #ff9515;
}
.container {
  padding: "40px";
}

.careers-container {
  position: relative;
  width: 100%;
}

.careers-container img {
  width: 100%;
  height: auto;
}

.overlay-text {
  position: absolute;
  top: 30%;
  left: 50px;
  width: 100%;
  color: black;
  padding: 10px;
  text-align: center;
  line-height: 55px;
  /* font-size: 24px;  */
}
.join-text {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 46px;
  color: #ff9515;
  text-align: left;
}
.team-text {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 46px;
  color: #363636;
  text-align: left;
}

.career-1 {
  font-family: "Poppins";
  font-weight: 700;
  color: #363636;
  font-size: 46px;
  line-height: 50px;
  overflow-y: hidden;
}

.career-2 {
  font-family: "Poppins";
  font-weight: 400;
  color: #363636;
  font-size: 16px;
}

.open {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 32px;
  color: #363636;
}

.right-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  gap: 15px;
}

.positions {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.position-item {
  height: 60px;
  width: 600px;
  background: #f3f3f3;
  padding: 10px;
  cursor: pointer;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 16px;
  color: #ff9515;
  display: flex;
  align-items: center;
}

.position-item:hover {
  background: #e9e9e9;
}

.plus-sign {
  display: inline-block;
  /* width: 10px */
  text-align: center;
  margin-right: 20px;
  color: #ccc;
}

@media (max-width: 1200px) {
  .career-1 {
    font-size: 40px;
    line-height: 38px;
  }
  .career-2 {
    font-size: 15px;
  }
  .open {
    font-size: 28px;
  }

  .position-item {
    font-size: 14px;
    width: 400px;
  }
}

@media (max-width: 992px) {
  .career-1 {
    font-size: 32px;
  }
  .career-2 {
    font-size: 14px;
  }

  .open {
    font-size: 24px;
  }

  .position-item {
    font-size: 12px;
  }

  .main-career,
  .right-div {
    padding: 20px;
  }
  .join-text {
    font-size: 40px;
  }
  .team-text {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .career-1 {
    font-size: 28px;
  }
  .career-2 {
    font-size: 13px;
  }

  .open {
    font-size: 20px;
  }

  .position-item {
    font-size: 11px;
    width: 100%;
  }
  .main-career {
    gap: 15px;
  }
  .main-career,
  .right-div {
    padding: 15px;
  }

  .positions {
    gap: 5px;
  }
  .overlay-text{
    line-height: 30px;
    left: 10px;
  }
  .join-text {
    font-size: 35px;
  }
  .team-text {
    font-size: 35px;
  }

  /* Move the right-div to the next row */
  .container {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .career-1 {
    font-size: 22px;
    line-height: 25px;
  }
  .overlay-text{
    line-height: 30px;
    left: 10px;

    top: 20%;
  }
  .join-text {
    font-size: 25px;
  }
  .team-text {
    font-size: 25px;
  }
  .open {
    font-size: 18px;
  }

  .position-item {
    font-size: 10px;
    height: 50px;
  }
  .main-career {
    gap: 10px;
  }
  .main-career,
  .right-div {
    padding: 10px;
  }

  .positions {
    gap: 3px;
  }

  /* Move the right-div to the next row */
  .container {
    flex-direction: column;
  }
}
