/* .team-main {
  background-image: url(../../utils/Icons/Rectangle\ 46.svg);
  height: 200px;
}
.team-flex {
  display: flex;
}
.team-1 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 45px;
  text-align: left;
  color: white;
  margin-bottom: 0;
}
.button-div-1 {
    display: flex;
    justify-content: flex-start;
}
.background-new{
  width: "100%";
  height: "100%";
  object-fit: "cover";
  padding-top: "30px";
  padding-bottom: "10px"
}

} */
.team-main{
  background: white;
}
.sub-div-team{
  display: flex;
   background-image: url('../../utils/Icons/Rectangle\ 46.svg');
}
.team-1 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 45px;
  text-align: left;
  color: white;
  margin-bottom: 0;
}
.button-div-1 {
  display: flex;
  justify-content: flex-start;
}
@media (max-width: 992px) {
  .team-1 {
    font-size: 42px;
    text-align: left;
  }
}
@media (max-width: 768px) {
  .team-1 {
    font-size: 36px;
    text-align: left;
  }
  .global {
    font-size: 38px;
    text-align: left;
  }
}
@media (max-width: 480px) {
  .team-1 {
    font-size: 32px;
    text-align: left;
  }
  .global {
    font-size: 34px;
    text-align: left;
  }
}