* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.services {
  background: white;
  overflow-x: hidden;
}

.heading {
  margin-top: 8%;
}

.service-heading {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 40px;
  text-align: center;
  color: black;
  /* line-height: 54px; */
}

.card-container {
  padding: 50px 100px;
  box-sizing: border-box;
  border-radius: 10px;
}

.service-card {
  width: 100%;
  height: 160px;
  border: 1px solid black;
  border-radius: 17px;
  transition: background-color 0.3s ease;
}

.card1 {
  padding-left: 20px;
  padding-top: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: color 0.3s ease;
}

.font-web {
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 700;
  color: black;
  text-align: left;
  padding-left: 2px;
  box-sizing: border-box;
}

.service-card:hover {
  background-color: #363636;
}

.service-card:hover .font-web {
  color:  #FF9515;
}

.service-card:hover img {
  filter: brightness(0) invert(1);
  color: white; 
}
.icon {
  color: #FF9515; 
  transition: color 0.3s ease; 
}

@media (max-width: 600px) {
  .service-heading {
    font-size: 30px;
  }

  .card-container {
    padding: 20px;
    align-items: center;
  }

  .card1 {
    padding-left: 10px;
    padding-top: 20px;
  }
}

@media (max-width: 480px) {
  .services {
    padding: 30px;
  }
}
