.container1 {
  background: #E3E3E3;
  overflow-x: hidden;
}
.sub-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.about {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 17px;
  text-align: right;
  color: #ff9515;
  margin-bottom: 0;
}
.main-about {
  margin-top: 15%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  width: 50%;
}
.global {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 45px;
  text-align: right;
  color: #363636;
  margin-bottom: 0;
}
.raigad {
  color: #363636;
  text-align: justify;
  font-size: 16px;
  font-family: "Poppins";
  margin-bottom: 0;
  font-weight: 400;
}
.global-about {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.bg-card {
  height: 240px;
  width: 330px;
  background-color: #363636;
  border: 1px solid #ff9515;
  border-radius: 20px;
  padding: 20px;
}
.card-title-1-1 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 18px;
  color: white;
  text-align: left;
  margin: 0;
}

.card-sub-content-1 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: white;
  text-align: left;
  margin: 0;
  overflow-y: hidden;
}

.raigad-1 {
  color: #363636;
  text-align: left;
  font-size: 12px;
  font-family: "Poppins";
  margin-bottom: 0;
}
.button-div {
  display: flex;
  justify-content: flex-end;
}

.button-about {
  /* justify-content: flex-start; */
  padding: 10px 30px;
}
.read-more {
  color: white;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
}
.card-about-2{
    display: flex;
    padding: 10px 0px 0px 0px;
    gap: 40px;
    justify-content: space-between;
}

@media (max-width: 992px) {
  .main-about {
    width: 100%;
    margin-top: 5%; 
   padding: 50px;
  }

  .sub-container {
    flex-direction: column;
    align-items: center;
  }
  .button-div {
    display: flex;
    justify-content: flex-start;
  }
  .about{
    text-align: left;
  }
  .global {
    font-size: 42px;
    text-align: left;
  }
  .read-more {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .card-title-1-1 {
    font-size: 16px;
  }
  .card-sub-content-1 {
    font-size: 12px;
  }
  .raigad {
    font-size: 14px;
  }
  .card-about-2{
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
  }
  .button-div {
    display: flex;
    /* justify-content: center; */
  }
  .read-more {
    font-size: 15px;
  }
  .global {
    font-size: 38px;
    text-align: left;
  }
 
}

@media (max-width: 480px) {
  .card-title-1-1 {
    font-size: 14px;
  }
  .card-sub-content-1 {
    font-size: 12px;
  }
  .read-more {
    font-size: 14px;
  }
  .bg-card {
    height: 220px;
    width: 100%;
  }
  .raigad {
    font-size: 12px;
  }
  .global {
    font-size: 34px;
    text-align: left;
  }
  .raigad{
    text-align: left;
  }
}
